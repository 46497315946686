// https://observablehq.com/@tsenyi/collapsible-indented-tree@797
import define1 from "./a33468b95d0b15b0@808.js";
import { process } from "process";
import hierachyServices from "../services/hierachyServices.ts";
function _1(md) {
  return (
    md``
  )
}
let _source;
let originalSource;
let firstUpdate = true;
let lastNodeSelected;
let clickIcon = false;
let infoMargin;
let globeMargin;
let doneLoaded = false;
async function updateCollapsedStatusByUUID(obj, targetUUID) {

  console.log({ obj })
  console.log({ targetUUID })
  /*
  obj.collapsed = false;
  obj._children[0].collapsed = false;
  obj._children[0]._children[0].collapsed = false;

  obj.children = obj.children ? null : obj._children;

  obj.children[0].children = obj.children[0].children ? null : obj.children[0]._children;

  obj.children[0].children[0].children = obj.children[0].children[0].children ? null : obj.children[0].children[0]._children;
*/
  /*
    if (obj.data && obj.data.UUID === targetUUID) {
      console.log({ openNode: obj.data })
      obj.collapsed = false;
      obj.children = obj.children ? null : obj._children;
      updateParentsCollapsedStatus(obj);
      console.log({ found: obj })
      return true;
    }
    if (obj.children && obj.children.length > 0) {
      for (const child of obj.children) {
        const found = updateCollapsedStatusByUUID(child, targetUUID);
        if (found) {
          obj.collapsed = false;
          obj.children = obj.children ? null : obj._children;
          // updateParentsCollapsedStatus(obj);
          break;
        }
      }
    }
    return obj;
  */
  /*
   let resultado = buscarObjetoPorUUID(targetUUID, obj);
   console.log({ resultado })
   return updateHierarchySource(obj, resultado)
   */
  let resultado = searchSourceByUUUID(obj, targetUUID)
  resultado = expandNode(resultado)
  console.log({ resultado })
  return resultado;
};

function updateHierarchySource(source, objetoEncontrado) {
  console.log({ objetoEncontrado })

  if (objetoEncontrado !== undefined) {
    if (objetoEncontrado.TYPE !== undefined) {


      source.children = source.children ? null : source._children;

      source.children[0].children = source.children[0].children ? null : source.children[0]._children;

      source.children[0].children[0].children = source.children[0].children[0].children ? null : source.children[0].children[0]._children;
      /*
      if (objetoEncontrado.TYPE !== "Room") {
        console.log("NIVEL 3")
      }
      */
    } else {
      if (objetoEncontrado.children !== null) {
        if (objetoEncontrado.children[0].data.TYPE === "Room") {
          console.log({ abrir: objetoEncontrado.children[0].data.TYPE })
          source.collapsed = false;
          source._children[0].collapsed = false;
          source._children[0]._children[0].collapsed = false;
          source.children = source.children ? null : source._children;
        }
      }
    }




  }

  //source.children[0].children = source.children[0].children ? null : source.children[0]._children;

  //source.children[0].children[0].children = source.children[0].children[0].children ? null : obj.children[0].children[0]._children;

  return source;
}

function searchSourceByUUUID(source, uuid) {
  if (source._children !== undefined) {
    //si es vacio abrir el ultimo padre
    if (uuid === "") {
      console.log("ABIR primer NODO")
      console.log('%c' + 'PRIMER', 'color: green');
      return source;
    } else {
      for (let i = 0; i < source._children.length; i++) {
        //Encontrado
        if (source._children[i].data._id === uuid) {

          console.log('%c' + 'Encontrado', 'color: white ; background-color: green');
          console.log({ "P_Encontrado": source._children[i] })
          const data = { ...source._children[i].data, isVolumeEvent: true }
          dispatchCustomEvent("hierarchyNodeEvent", data);
          const input = document.getElementById("search-input");
          input.value = source._children[i].data.name;
          source._children[i] = expandNode(source._children[i])

          expandAllParents(source, source._children[i]).then(expanded => {
            return expanded;
          })
        } else {
          //re
          searchSourceByUUUID(source._children[i], uuid)
        }

      }
    }

    return source;
  } else {
    console.log("E");
    return source;
  }
}
function expandNode(source) {

  if (source._children !== undefined) {
    source.collapsed = false;
    source.children = source._children;
  }
  return source;
}
async function expandAllParents(source, found) {


  function expandParent(child) {
    if (child.parent !== null) {
      //return expandParent(objeto.arreglo);
      child = expandNode(child)
      expandParent(child.parent)
    } else {
      //     return null;
      return source;
    }
  }
  expandParent(found);

}

function buscarObjetoPorUUID(uuid, data) {
  /*
  console.log({ "hijos": json.children })
  console.log({ "dentor": json.json })
  console.log({ "datra": json.json })
  */
  console.log({ buscarJson: data })
  //console.log({ childdrem: data.children[0] ? data.children[0] : "No Hijos" })
  //console.log({ lenght: data.children.length })
  /*
  if (json.children !== undefined && json.children !== null) {
    console.log({ BUSACR: uuid })
    console.log({ "localUUID": json.data.UUID })
    if (json.data.UUID === uuid) {
      return json;
    }
    if (json.children.length > 0) {

      for (let i = 0; i < json.children.length; i++) {
        const resultado = buscarObjetoPorUUID(uuid, json.children[i]);

        console.log({ resultado })
        if (resultado !== null) {
          console.log("ENCUENTRA")
          return resultado;
        }
      }
    }
  }

  return null;
  */
  if (data.data !== undefined) {
    //console.log("C")
    if (data.data.UUID === uuid || uuid === "") {
      console.log("D")
      console.log("EXITO");
      return data
    }
    else if (data.children !== undefined || data.children !== null) {
      //data.children.length > 0

      // console.log({ children: data.children[0] })
      // console.log({ length: data.length })
      if (data.children === null) {
        console.log({ "CHILDNULL": data })
        if (data.data.children) {

          for (let i = 0; i < data.data.children.length; i++) {
            if (data.data.children[i].UUID === uuid) {
              return data.data.children[i]
            }
          }

        }

      } else if (data.children[0].data.UUID) {
        if (data.children[0].data.UUID === uuid) {
          console.log("UUID " + data.children[0].data.UUID)
          console.log("EXITO");
          return data.children[0]

        }
      }

      if (data.children !== null) {

        if (data.children[0].children) {
          console.log("F " + data.children.length)
          for (let i = 0; i < data.children[0].children.length; i++) {
            let child = buscarObjetoPorUUID(uuid, data.children[0].children[i]);
            if (child) {
              console.log({ childEncontrado: child })
              return child
            }
          }
        }
      }



    }
  }
  else {
    console.log({ dataB: data })


    //    return null;
  }



}

function changeStyleNodeSelected(uuid, btnClicked) {
  document.querySelectorAll('.parent-selected-node').forEach(element => {
    element.classList.remove('parent-selected-node');
  });
  document.querySelectorAll('.selected-node-text').forEach(element => {
    element.classList.remove('selected-node-text');
  });

  console.log({ uuid })
  console.log({ "CE": btnClicked })
  const id = uuid;
  try {

    const selected = document.querySelector(`[uuid="${id}"]`)
    selected.classList.add('selected-node-text');
    if (selected !== undefined) {
      console.log({ selected })
      console.log({ id })
      // Check if the element has the class "my-class"
      const hasClass = selected.classList.contains("selected-node-text");
      if (lastNodeSelected !== undefined) {
        //  changeRectColorFillByTextID(lastNodeSelected, "none");
      }


      // If the element has the class, remove it
      if (hasClass) {
        selected.classList.remove("selected-node-text");
      }

      setTimeout(function () {

        selected.classList.add('selected-node-text');
        const container = document.getElementById("hierarchy-container");

        selected.parentElement.classList.add('parent-selected-node');
        //  changeRectColorFillByTextID(selected.id, "#dee2e6");
        if (btnClicked === false) {
          selected.scrollIntoView();
          container.scrollLeft = 0;
        }
        lastNodeSelected = id;


      }, 100);
    }

  } catch (error) {
    return;
  }




}
function dispatchCustomEvent(eventName, data) {
  const event = new CustomEvent(eventName, { detail: data });
  console.log("EVENTO")
  window.dispatchEvent(event);
  if (eventName === "positionEvent") {
    setTimeout(function () {
      console.log('Han pasado 1 segundo');
      clickIcon = false;
    }, 1000);
  }

}


const updateParentsCollapsedStatus = (obj) => {
  if (obj.parent) {

    // obj.parent.collapsed = false;
    // obj.parent.children = obj.parent.children ? null : obj.parent._children;
    //  updateParentsCollapsedStatus(obj.parent);
    // console.log({ parent: obj.parent })
  }
};

function changeRectColorFillByTextID(uuid, color) {

  const selected = document.querySelector(`[uuid="${uuid}"]`);
  console.log({ selected, uuid })

  const rect = selected.parentElement.querySelector("rect:first-of-type");
  rect.setAttribute("fill", color);
}
function openNodeByUUID(uuid) {
  console.log({ uuid })
}

function _chart(d3, indexEachBefore, nodeSize, width, _, root, identifyDescendants, postIdentifyFns) {
  if (doneLoaded === false) {
    console.log("EXPAND NODE")
    dispatchCustomEvent("doneLoaded", true);
    doneLoaded = true;
  }
  // Selecciona el div contenedor
  var container = d3.select("#auto-complete-container");
  container.on("click", function () {
    let list = document.getElementById("auto-complete-container");
    console.log({ list })
    const target = d3.event.target;
    console.log({ conainerTarget: target })
    var hijos = list.children;
    var UUID = target.id;
    console.log({ target })
    if (UUID !== undefined && UUID !== "") {
      updateCollapsedStatusByUUID(_source, UUID)
        .then((res) => {
          update(res)
          changeStyleNodeSelected(UUID, false);
        })

      list.classList.add('hidden')
      console.log("click en container")
    }

  })
  var eventVolumeDiv = d3.select("#eventVolume");
  eventVolumeDiv.on("volumeEvent", function () {
    console.log({ "CUSTOM volume": d3.event.target })
    const UUID = d3.event.target.attributes.volumeid.value;


    if (UUID !== undefined && UUID !== "") {
      updateCollapsedStatusByUUID(_source, UUID)
        .then((res) => {
          update(res)
          changeStyleNodeSelected(UUID, false);
        })
    }

  })
  /*
  
    // Iniciamos el timer
    const timer = setInterval(() => {
      const pizzaDiv = document.querySelector("#search-input");
      console.log("INTERVAL");
      // Comprobamos si el div existe
      if (pizzaDiv) {
        // Terminamos el timer
        const inputElement = d3.select('#search-input');
        console.log({ inputElement })
        // Agrega un evento 'input' para detectar cambios en el input
        inputElement.on('input', function () {
  
  
  
          // Obtiene el valor del input
          const texto = this.value;
          if (originalSource.data.children !== undefined) {
            const fiterByQuery = originalSource.children.filter(objeto => objeto.data.name.toLowerCase().includes(texto.toLowerCase()));
  
            if (fiterByQuery.length > 0) {
              _source.children = fiterByQuery;
              _source._children = _source.children;
              update(_source)
            } else {
              _source.children = originalSource.children;
              _source._children = originalSource._children;
              update(_source)
            }
          }
  
        });
        clearInterval(timer);
      }
    }, 1000);
  */

  const svg = d3.create("svg")
    .classed("CollapibleIndentedTree", false)
    .style("overflow", "visible");

  const gLink = svg.append("g")
    .attr("name", "link")
    .attr("fill", "none")
    .attr("stroke", "#999");

  const gIcon = svg.append("g")
    .attr("name", "icon")
    .attr("fill", "none")
    .attr("stroke", "#999");

  const gNode = svg.append("g")
    .attr("class", "gNode")
    ;

  const createJsonFile = () => {
    var data = { name: "John", age: 31, city: "New York" };
    var json = JSON.stringify(data);
    var blob = new Blob([json], { type: "application/json" });
    var url = URL.createObjectURL(blob);

    var a = document.createElement("a");
    a.href = url;
    a.download = "data.json";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

  }

  function update(source) {

    if (firstUpdate) {
      if (source.data.root !== undefined) {
        source.data.name = source.data.root.spaceName;
      } else {
        source.data.name = source.data.name;
      }

      originalSource = source;
      console.log({ firstUpdate: source })
      firstUpdate = false;
    } else {
      source.data.name = source.data.root.spaceName;
      source.data.isRoot = true;
    }
    _source = source;
    console.log({ UPDATE: source })
    // d3.tree()(root)
    //createJsonFile(source);
    const duration = d3.event && d3.event.altKey ? 2500 : 250;
    const nodes = source.descendants()//.reverse();
    const links = source.links();
    indexEachBefore(source);//re-calculate the displaying nodes' index for showing at correct 'y'
    const height = (nodes.length + 1) * nodeSize//top.y - bottom.y + margin.top + margin.bottom;
    const transition = svg.transition()
      .duration(duration)
      //.attr("viewBox", [-nodeSize / 2, -nodeSize * 3 / 2, width, height])
      .attr("height", height)
      .attr("id", "hierarchySvg")
      .tween("resize", window.ResizeObserver ? null : () => () => svg.dispatch("toggle"));

    // Update the nodes…awsdz
    const node = gNode.selectAll("g")
      .data(nodes, d => d.id);

    // Enter any new nodes at the parent's previous position.
    const nodeEnter = node.enter().append("g")
      .attr("transform", d => `translate(0,${d.index * nodeSize})`)
      .attr("fill-opacity", 0)
      .attr("stroke-opacity", 0)
      .attr("cursor", d => _.has(d, 'collapsed') ? "pointer" : null)
      .style("position", "relative")
      .style("z-index", "2")

      .attr("pointer-events", "all")
      .on("click", d => {
        console.log({ clicked: nodeEnter })
        if (clickIcon === false) {
          if (!_.has(d, 'collapsed')) return;



          d.collapsed = !d.collapsed;
          d.children = d.children ? null : d._children;


          update(_source);
        }

        //  openNode("FIT1563B COLD LOOP DELIVERY");
      }).on('mouseover', function (d) {
        // when the bar is mouse-overed, we highlight the row.
        d3.select(this).classed('hover', true).style('opacity', 1).attr("fill", "#6c5dd3");
      })
      .on('mouseout', function (d) {
        d3.select(this).classed('hover', false).style('opacity', 0.8).attr("fill", "#000000")
      });


    nodeEnter.append("circle")
      .attr("cx", d => d.depth * nodeSize)
      .attr("r", d => _.has(d, 'collapsed') ? 0 : 2.5)
      .attr("fill", d => _.has(d, 'collapsed') ? null : "#999")


    //rect for mouse motion indication
    nodeEnter.append("rect")
      .attr("dy", "0.32em")
      .attr("x", d => d.depth * nodeSize + 5)
      .attr("y", "-0.62em")
      .attr("width", "100%").attr("height", nodeSize)
      .attr("fill", "none")
      .attr("pointer-events", "none");
    let nodeWidth = 0;
    nodeEnter.append("text")
      .attr("dy", "0.32em")

      .attr("x", d => `${d.data.hasOwnProperty('root') ? nodeSize + 20 : d.depth * nodeSize + 90}`)
      .attr("class", d => `nodeText nodeB ${d.data.hasOwnProperty('root') ? 'miClase' : ''}`)
      .attr("width", "100%")
      .attr("id", d => {
        return d.data._id;
      })
      .attr("uuid", d => d.data._id)
      .text(d => d.data.name)

      .on("click", d => {
        console.log({ "icon click": d })
        if (d.data._id) {

          console.log({ current: d })
          d.data.detailsClicked = false;
          dispatchCustomEvent("detailsClickEvent", d.data);
          dispatchCustomEvent("hierarchyNodeEvent", d.data);
          changeStyleNodeSelected(d.data._id, true)
          // d.stopPropagation();
        }
      })



    // console.log({ text }); // 140
    if (localStorage.getItem("role") === "Admin") {
      console.log({ nodeEnter })
      nodeEnter.append("text")
        .attr("class", ` hierachy-icon `)
        .attr("dy", "0.32em")
        //.attr("y", "-0.62em")
        // .attr("x", 550)

        .attr("x", d => {
          let x = `${d.depth * nodeSize + 65}`
          if (d.data.hasOwnProperty('root')) {
            return 10
          } else {
            return x

          }


        })
        .attr("width", 40)
        .attr("height", 20)
        .style("position", "relative")
        .style("z-index", "10")
        // .append("xhtml:div")
        .style("font", "20px")
        .text("🔨")
        .on("click", d => {
          console.log({ "option": d })
          if (d.data._id || d.data.hasOwnProperty('root')) {
            clickIcon = true;
            dispatchCustomEvent("nodeOptionEvent", d.data);
            dispatchCustomEvent("detailsClickEvent", d.data);
            changeStyleNodeSelected(d.data._id, true)
            console.log({ d })
          }
        })
      infoMargin = 15;
      globeMargin = 40;
    } else {
      infoMargin = 53;
      globeMargin = 17;
    }



    nodeEnter.append("text")
      .attr("class", d => `hierachy-icon ${d.data.hasOwnProperty('root') || (d.data.sweepCameraPosition.sweepointUUID === "" || d.data.sweepCameraPosition.sweepointUUID === null) ? 'hidden no-pointer-events' : ''}`)
      .attr("dy", "0.32em")
      //.attr("y", "-0.62em")
      // .attr("x", 550)
      .attr("x", d => d.depth * nodeSize + globeMargin)
      .attr("width", 40)
      .attr("height", 20)
      .style("position", "relative")
      .style("z-index", "10")
      // .append("xhtml:div")
      .style("font", "20px")
      .text("🌐")
      .on("click", d => {
        console.log({ "icon click": d })
        if (d.data._id) {
          clickIcon = true;
          dispatchCustomEvent("positionEvent", d.data);
          changeStyleNodeSelected(d.data._id, true)
        }
      })

    nodeEnter.append("text")
      .attr("class", d => `hierachy-icon ${d.data.hasOwnProperty('root') ? 'hidden no-pointer-even' : ''}`)
      .attr("dy", "0.32em")
      //.attr("y", "-0.62em")
      // .attr("x", 550)
      .attr("x", d => d.depth * nodeSize + infoMargin)
      .attr("width", 40)
      .attr("height", 20)
      .style("position", "relative")
      .style("z-index", "10")
      // .append("xhtml:div")
      .style("font", "20px")
      .text("📃")
      .on("click", d => {
        console.log({ "icon click": d })
        console.log({ data: d })
        if (d.data._id !== undefined) {
          d.data.detailsClicked = true;
          console.log({ data: d.data })
          clickIcon = true;
          dispatchCustomEvent("detailsClickEvent", d.data);
          changeStyleNodeSelected(d.data._id, true)
        }
      })

    // .clone(true).lower();
    /*
        nodeEnter.append("text")
          .attr("class", "hierachy-icon")
          .attr("dy", "0.32em")
          .attr("x", d => d.depth * nodeSize)
          // Set the right and bottom properties of the child div to 0.
    
          .text("#");
    */
    /*
           .attr("font-family", "bootstrap-icons")
           .attr("font-size", "100")
           .attr("x", 550)
           .attr("dy", "0.32em")
           .style("fill", "red");*/




    /*
        nodeEnter.append("text")
          .attr("class", "bi bi-heart")
          .attr("dy", "0.32em")
          .attr('class', 'glyphicon')
          .text('\ue059')
          .attr("x", 550)
          .attr("id", d => d.data.name)
          .attr("uuid", d => d.data.UUID)
    
    */
    nodeEnter.append("title")
      .text(d => d.ancestors().reverse().map(d => d.data.name).join("/"));



    //loop the columns to display
    //providing 'data' fn incase summing up by child is not needed. 
    //If not passed, summing children values (defined through 'value' prop in columns) up.
    /*
    for (const { label, value = d => d.value, format, x,
      data,
      fillBy = value, fill = () => null } of columns) {
      console.log(label)
      svg.append("text")
        .attr("dy", "0.32em")
        .attr("y", -nodeSize)
        .attr("id", "CONTADOR")
        .attr("x", x)
        .attr("text-anchor", "end")
        .attr("font-weight", "bold")
        .text(label);

      let columnText = nodeEnter.append("text");
      columnText.attr("dy", "0.32em")
        .attr("x", x)
        .attr("text-anchor", "end");

      if (data) {
        const dat = data(source)
        columnText.data(dat, d => d.id)
      }
      columnText.attr("val", value)
        .attr("id", "VALOR")
        .attr("fill", d => fill(fillBy(d)))//d=>color.color(d.value))
        .text(d => format(value(d), d));

    }
    */
    // Transition nodes to their new position.
    const nodeUpdate = node.merge(nodeEnter).transition(transition)
      .attr("transform", d => `translate(0,${d.index * nodeSize})`)
      .attr("cursor", d => _.has(d, 'collapsed') ? "pointer" : null)
      .attr("pointer-events", d => "all")
      .attr("fill-opacity", 1)
      .attr("stroke-opacity", 1);

    // Transition exiting nodes to the parent's new position.
    const nodeExit = node.exit().transition(transition).remove()
      .attr("transform", d => `translate(0,${d.index * nodeSize})`)
      .attr("fill-opacity", 0)
      .attr("stroke-opacity", 0);

    // Update the links…
    const link = gLink.selectAll("path")
      .data(links, d => d.target.id);

    // Enter any new links at the parent's previous position.
    const xOffset = -2, yOffset = 3;
    const linkEnter = link.enter().append("path")//.transition(transition)
      .attr("d", d => `
        M${d.source.depth * nodeSize},${d.source.index * nodeSize + yOffset}
        V${d.target.index * nodeSize}
        h${nodeSize + xOffset}
      `);

    // Transition links to their new position.
    link.merge(linkEnter)//.transition(transition)
      .attr("d", d => `
        M${d.source.depth * nodeSize},${d.source.index * nodeSize + yOffset}
        V${d.target.index * nodeSize}
        h${nodeSize + xOffset}
      `);

    // Transition exiting nodes to the parent's new position.
    link.exit().remove()
      .attr("d", d => `
        M${d.source.depth * nodeSize},${d.source.index * nodeSize + yOffset}
        V${d.target.index * nodeSize}
        h${nodeSize + xOffset}
      `);

    //triangle icons for expandable/collapible objects
    const icon = gIcon.selectAll("path")
      .data(source.descendants().filter(d => _.has(d, 'collapsed')), d => d.id);

    const iconPathData = d => _.has(d, 'collapsed') ? (d.collapsed ? "M7 4L1 8V0z" : "M4 7L0 1h8z") : ''
    const iconEnter = icon.enter().append("path")
      // .attr("transform", d => `translate(${d.depth * nodeSize-4},${d.index * nodeSize-3})`)
      // .attr("fill", d => d.collapsed?"#999":null)
      .attr("d", iconPathData);

    // Transition links to their new position.
    icon.merge(iconEnter)
      .attr("transform", d => `translate(${d.depth * nodeSize - 4},${d.index * nodeSize - 4})`)
      .attr("fill", d => d.collapsed ? "#999" : null)
      .attr("cursor", "pointer")
      .attr("pointer-events", "all")
      .on("click", d => {
        if (!_.has(d, 'collapsed')) return;
        d.collapsed = !d.collapsed;
        d.children = d.children ? null : d._children;
        changeStyleNodeSelected(d.data._id, true)
        update(source);
      })
      .attr("d", iconPathData);

    // Transition exiting nodes to the parent's new position.
    icon.exit().remove();
    _source = source;

  }

  let copyOfRoot = root.copy();
  copyOfRoot = identifyDescendants.call(copyOfRoot, copyOfRoot, ...postIdentifyFns)
  _source = copyOfRoot;
  console.log({ copyOfRoot })
  update(copyOfRoot);
  return svg.node();
}

/*
function _3(md) {
  return (
    md`# Appendix
## Virables & Config`
  )
}
*/
function _data(FileAttachment) {
  console.log("is done");

  if (!('dataJson' in localStorage)) {
    console.log("NO data JSON")
    FileAttachment("flare-2.json").json().then(res => {
      //console.log({ asd: res })
      localStorage.setItem('dataJson', JSON.stringify(res));
    })

    return (
      FileAttachment("flare-2.json").json()
    )

  }


  else if ('dataJson' in localStorage) {
    return (
      JSON.parse(localStorage.getItem("dataJson"))
    )
  }


}

function _testData() {
  return (
    {
      "name": "hunter game",
      "children": [
        {
          "name": "round 1",
          "children": [
            { "name": "john", age: 35, score: 1, "children": [{ "name": "hit", "count": 1 }, { "name": "ko", "count": 0 }] },
            { "name": "Gill", age: 25, score: 2, "children": [{ "name": "hit", "count": 5 }, { "name": "ko", "count": 1 }] },
          ]
        },
        {
          "name": "round 2",
          "children": [
            { "name": "Gill", age: 25, score: 20 },
            { "name": "Zed", age: 45, score: 30 }
          ]
        },
        {
          "name": "round 3",
          "children": [
            { "name": "john", age: 35, score: 100 },
            { "name": "Gill", age: 25, score: 200 },
            { "name": "Zed", age: 45, score: 300 }
          ]
        }
      ]
    }
  )
}

function _nodeSize() {
  return (
    20
  )
}

function _cachedColumnData() {
  return (
    null
  )
}

function _columns(cachedColumnData, $0, subtotalForNodes, format, identifyDescendants, root) {
  return (
    [
      // {// item's raw prop
      //   label: "Age", 
      //   // data: root.copy().descendants(),
      //   value: d => d.data.age, 
      //   format: (value, d) => value ? format(value) : "",  
      //   x: 450,
      //   // fill: colors['byTotalSize']
      // },
      // {//aggregated using postFn for each group (having children)
      //   label: "Score", 
      //   data: diplayingTree=>subtotalForNodes(diplayingTree, d=>d.value),
      //   value: d=>d.value,
      //   format: (value, d) => value ? format(value) : "", 
      //   x: 550,
      //   // fillBy:d=>d.data.len,//after hireachy summmed up
      //   // fill: colors['byTagSize']
      // },
      /*
      {//aggregated using postFn for each group (having children)
        label: "posición",
        data: diplayingTree => cachedColumnData ? cachedColumnData : ($0.value = subtotalForNodes(diplayingTree, d => d.value)),
        value: d => d.value,
        format: (value, d) => value ? format(value) : "",
        x: 550,
      },
      {//count for groups. identifyDescendants() is important
        label: "Count",
        data: diplayingTree => identifyDescendants(root.copy()).count().descendants(),
        format: (value, d) => value > 1 ? format(value) : "",
        x: 640,
      }
      */
    ]
  )
}

function _root(d3, data) {
  return (
    d3.hierarchy(data)
  )
}
/*
function _10(md) {
  return (
    md`## Functions`
  )
}
*/
function _defaultCollapsed() {
  return (
    node => node.depth > 1 || node.id > 0 || node.data.name == 'animate'
  )
}

function _initDescendantDefaultCollapseState(defaultCollapsed) {
  return (
    function initDescendantDefaultCollapseState(d) {
      // console.log('ddc',d)
      if (d.children) {
        d.collapsed = defaultCollapsed(d);
        d._children = d.children;
        if (!!d.collapsed) d.children = null;
        // console.log('initCollapse',d.data.name)
      }
      return d;
    }
  )
}

function _identifyDescendants() {
  return (
    function identifyDescendants(r, ...postFns) {
      r.descendants().forEach((d, i) => {
        d.id = i++;
        if (postFns && postFns.length) {
          for (const j in postFns) {
            const fn = postFns[j]
            fn.call(r, d)
          }
        }
      })
      return r;//make it chainable
    }
  )
}

function _postIdentifyFns(initDescendantDefaultCollapseState) {
  return (
    [initDescendantDefaultCollapseState]
  )
}

function _indexEachBefore() {
  return (
    function indexEachBefore(r) {
      let i = 0;
      r.eachBefore(n => {
        n.index = i++;
      })
      return r
    }
  )
}

function _subtotalForNodes(root, identifyDescendants) {
  return (
    function subtotalForNodes(displayingTree, sumFn = d => d.value) {
      // const ids = displayingTree.descendants().map(d=>d.id)||[]
      let rcopy = root.copy()
      let ds = identifyDescendants(rcopy).sum(sumFn).descendants();
      // ds = ds.filter(d=>ids.indexOf(d.id)>-1)
      // ds = ds.sort(sortByIndex).slice(0,ids.length)
      // console.log('ids',ids)
      return ds
    }
  )
}

export const openNode = (id) => {
  console.log({ OPENODE: id })
  /*
  var objetoEncontrado = _source.children.find(function (objeto) {
    return objeto.data.name === id;
  });
  */
  objetoEncontrado = _source.children[0];
  objetoEncontrado.collapsed = !objetoEncontrado.collapsed;
  objetoEncontrado.children = objetoEncontrado.children ? null : objetoEncontrado._children;
  update(_source)
  console.log(objetoEncontrado);
}

function _format(d3) {
  return (
    d3.format(",")
  )
}
/*
function _18(md) {
  return (
    md`## Imports`
  )
}
*/
function _d3(require) {
  return (
    require("d3@5")
  )
}

function __(require) {
  return (
    require('lodash')
  )
}

async function _gtag(require) {
  await require("https://www.googletagmanager.com/gtag/js?id=UA-15703023-1").catch(
    () => { }
  );
  const dataLayer = (window.dataLayer = window.dataLayer || []);
  function gtag() {
    dataLayer.push(arguments);
  }
  gtag('js', new Date());
  gtag('config', 'UA-15703023-1');
  gtag('set', { 'content_group1': 'Realtime Visualization' });
  return gtag;
}

/*
function _24(html) {
  return (
    html`<style>
svg.CollapibleIndentedTree{
  font-size: 14x
}
.CollapibleIndentedTree g.hover rect{fill:#f4efef;opacity:1}
</style>`
  )
}
*/

function searchElementsByName(query) {

  console.log({ query })
  console.log({ _source })
  // Utiliza el método filter() para buscar objetos cuya propiedad "name" contenga "piz"

}

// Exporta la función para que otros archivos puedan usarla
export default function define(runtime, observer) {
  console.log({ "DEFINE": localStorage.getItem("space") })
  const main = runtime.module();
  function toString() { return this.url; }
  localStorage.getItem("space")

  if ('dataJson' in localStorage && 'space' in localStorage) {
    console.log("EXISTE DATAJSON Y SPACE")
    const space = localStorage.getItem("space");
    const dataJson = JSON.parse(localStorage.getItem("dataJson"));
    console.log({ dataJson })
    if (space !== dataJson.root._id) {
      const fileAttachments = new Map([
        ["flare-2.json", { url: `${process.env.REACT_APP_DOMAIN}/api/hierarchy/space/${localStorage.getItem("space")}`, mimeType: "application/json", toString }]
      ]);
      console.log({ fileAttachments })
      main.builtin("FileAttachment", runtime.fileAttachments(name => fileAttachments.get(name)));
    }
  } else if (!('dataJson' in localStorage) || !('space' in localStorage)) {
    const fileAttachments = new Map([
      ["flare-2.json", { url: `${process.env.REACT_APP_DOMAIN}/api/hierarchy/space/${localStorage.getItem("space")}`, mimeType: "application/json", toString }]
    ]);
    console.log({ fileAttachments })
    main.builtin("FileAttachment", runtime.fileAttachments(name => fileAttachments.get(name)));
  }

  //main.variable(observer()).define(["md"], _1);
  main.variable(observer("chart")).define("chart", ["d3", "indexEachBefore", "nodeSize", "width", "_", "root", "identifyDescendants", "postIdentifyFns"], _chart);
  //main.variable(observer()).define(["md"], _3);
  main.variable(observer("data")).define("data", ["FileAttachment"], _data);
  // main.variable(observer("testData")).define("testData", _testData);
  main.variable(observer("nodeSize")).define("nodeSize", _nodeSize);
  main.define("initial cachedColumnData", _cachedColumnData);
  main.variable(observer("mutable cachedColumnData")).define("mutable cachedColumnData", ["Mutable", "initial cachedColumnData"], (M, _) => new M(_));
  main.variable(observer("cachedColumnData")).define("cachedColumnData", ["mutable cachedColumnData"], _ => _.generator);
  // main.variable(observer("columns")).define("columns", ["cachedColumnData", "mutable cachedColumnData", "subtotalForNodes", "format", "identifyDescendants", "root"], _columns);
  main.variable(observer("root")).define("root", ["d3", "data"], _root);

  main.variable(observer()).define(["openNode"], openNode);


  main.variable(observer("defaultCollapsed")).define("defaultCollapsed", _defaultCollapsed);
  main.variable(observer("initDescendantDefaultCollapseState")).define("initDescendantDefaultCollapseState", ["defaultCollapsed"], _initDescendantDefaultCollapseState);
  main.variable(observer("identifyDescendants")).define("identifyDescendants", _identifyDescendants);
  main.variable(observer("postIdentifyFns")).define("postIdentifyFns", ["initDescendantDefaultCollapseState"], _postIdentifyFns);
  main.variable(observer("indexEachBefore")).define("indexEachBefore", _indexEachBefore);
  main.variable(observer("subtotalForNodes")).define("subtotalForNodes", ["root", "identifyDescendants"], _subtotalForNodes);
  main.variable(observer("format")).define("format", ["d3"], _format);

  //main.variable(observer()).define(["md"], _18);

  const child1 = runtime.module(define1);
  main.import("legend", child1);
  main.variable(observer("d3")).define("d3", ["require"], _d3);
  main.variable(observer("_")).define("_", ["require"], __);
  main.variable(observer("gtag")).define("gtag", ["require"], _gtag);
  //main.variable(observer()).define(["html"], _24);
  return main;
}


export { searchElementsByName };