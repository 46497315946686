import React, {
  ReactNode,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";

import classNames from "classnames";
import { useTranslation } from "react-i18next";
import Brand from "../../../layout/Brand/Brand";
import Navigation, {
  NavigationLine,
} from "../../../layout/Navigation/Navigation";
import User from "../../../layout/User/User";
import {
  componentPagesMenu,
  dashboardPagesMenu,
  demoPagesMenu,
  gettingStartedPagesMenu,
  pageLayoutTypesPagesMenu,
} from "../../../menu";
import ThemeContext from "../../../contexts/themeContext";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import Hand from "../../../assets/img/hand.png";
import HandWebp from "../../../assets/img/hand.webp";
import Icon from "../../../components/icon/Icon";
import Button from "../../../components/bootstrap/Button";
import useDarkMode from "../../../hooks/useDarkMode";
import logoSide from "../../../assets/img/logoMirrasetCuadrado.png"
import Aside, {
  AsideBody,
  AsideFoot,
  AsideHead,
} from "../../../layout/Aside/Aside";

const DefaultAside = () => {
  const refAsideLinks = useRef<HTMLDivElement>(null);

  const {
    asideStatus,
    setAsideStatus,
    isLandscape,
    mobileDesign,
    setLeftMenuStatus,
    setRightMenuStatus,
    leftMenuStatus,
    rightMenuStatus,
  } = useContext(ThemeContext);

  const [doc, setDoc] = useState(localStorage.getItem("facit_asideDocStatus") === "true" || false);
  const [reloadAside, setReloadAside] = useState(false);
  const [menu, setMenu] = useState({});
  const { t } = useTranslation(["translation", "menu"]);

  const { darkModeStatus } = useDarkMode();


  useEffect(() => {


    if (refAsideLinks.current !== null) {
      if (refAsideLinks.current !== undefined) {
        const primerHijo = refAsideLinks.current?.firstElementChild;

        const role = localStorage.getItem("role");
        const ulChildre = primerHijo?.childNodes;
        if (ulChildre !== undefined) {
          ulChildre.forEach((child: any) => {
            if (
              child.firstChild.attributes.href.value ===
              "/" + dashboardPagesMenu.users.path &&
              role !== "Admin"
            ) {
              child.classList.add("hidden");
            }
          });
        }
      }

      //console.log({ current: });
    }
  }, []);

  const getMenuByRole = (role: string) => {

    return Object.values(dashboardPagesMenu).filter(menuItem => {
      if (role === 'Admin') {
        return true;
      }
      return menuItem!.permission === 'All';
    });
  };

  const validatePermissions = () => {
    const localUser = (typeof localStorage.getItem('role') === "string") ? localStorage.getItem('role') : '';
    const filteredMenu = getMenuByRole(localUser!);
    setMenu(filteredMenu);
  }

  useEffect(() => {
    validatePermissions();
    if (isLandscape === false) {
      setReloadAside(false);
      setTimeout(() => {
        setReloadAside(true);
        setLeftMenuStatus(false);
        setRightMenuStatus(false);
        setAsideStatus(false);
      }, 200);
    } else {
      setReloadAside(true);
      setTimeout(() => {
        setReloadAside(true);
        //  setAsideStatus(true);
        setLeftMenuStatus(false);
        setRightMenuStatus(false);
        setAsideStatus(false);
      }, 1000);
    }

  }, [isLandscape]);

  useEffect(() => {
    console.log({ asideStatus, leftMenuStatus, rightMenuStatus });
  }, [asideStatus, leftMenuStatus, rightMenuStatus, menu]);

  return (
    <>
      <span>

      </span>
      {!reloadAside ? (
        <></>
      ) : (
        <Aside>
          <li id="sideLogo" className="navigation-item"><a className="navigation-link navigation-link-pill" href="/">
            <span className="navigation-link-info">
              <img width="25" className="svg-icon--material svg-icon navigation-icon" src={logoSide} ></img>

              <span className="navigation-text">Mirrasset</span></span></a></li>

          <AsideBody>

            {!doc && (
              <>
                <Navigation
                  ref={refAsideLinks}
                  menu={menu}
                  id="aside-dashboard"
                />
                <NavigationLine />
              </>
            )}

            {doc && (
              <>
                <Navigation menu={gettingStartedPagesMenu} id="aside-docMenu" />
                <NavigationLine />
                <Navigation
                  menu={componentPagesMenu}
                  id="aside-componentsMenu"
                />
                <NavigationLine />
              </>
            )}

            {asideStatus && doc && (
              <Card className="m-3 ">
                <CardBody className="pt-0">
                  <img
                    srcSet={HandWebp}
                    src={Hand}
                    alt="Hand"
                    width={130}
                    height={130}
                  />
                  <p
                    className={classNames("h4", {
                      "text-dark": !darkModeStatus,
                      "text-light": darkModeStatus,
                    })}
                  >
                    {t("Everything is ready!") as ReactNode}
                  </p>
                  <Button
                    color="info"
                    isLight
                    className="w-100"
                    onClick={() => {
                      localStorage.setItem("facit_asideDocStatus", "false");
                      setDoc(false);
                    }}
                  >
                    {t("Demo Pages") as ReactNode}
                  </Button>
                </CardBody>
              </Card>
            )}

          </AsideBody>
          <AsideFoot>
            <nav aria-label="aside-bottom-menu">
              <div className="navigation"></div>
            </nav>
            <User />
          </AsideFoot>
        </Aside >
      )}
    </>
  );
};

export default DefaultAside;
