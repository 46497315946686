import React, { useContext, useEffect } from 'react';
import { useWindowSize } from 'react-use';
import ThemeContext from '../../contexts/themeContext';

const WrapperOverlay = () => {
	const { width } = useWindowSize();
	const { asideStatus, setAsideStatus, setLeftMenuStatus, setRightMenuStatus } =
		useContext(ThemeContext);
	useEffect(() => {
		console.log({
			asideStatus, width, brake: Number(process.env.REACT_APP_ASIDE_MINIMIZE_BREAKPOINT_SIZE),

			openWrapper: width < Number(process.env.REACT_APP_ASIDE_MINIMIZE_BREAKPOINT_SIZE)
		})
	}, [asideStatus])
	if (asideStatus && width < Number(1200))
		return (
			<div
				role='presentation'
				onClick={() => {
					setAsideStatus(false);
					setLeftMenuStatus(false);
					setRightMenuStatus(false);
				}}
				className='wrapper-overlay'
			/>
		);

	return null;
};
export default WrapperOverlay;
